.success-row td {
  background: rgba(52, 195, 143, 0.2) !important;
}

.danger-row td {
  background: rgba(244, 106, 106, 0.2) !important;
}

.warning-row td {
  background: #b6a81133 !important;
}
